import React from 'react';


const SectionTitleWhyChooseus = ({subtitle, title, description, textAlignment, textColor}) => {
    return (
        <div className={`section-heading`}>
            <div className="subtitle"></div>
            <h4 className="unique">Our Lifecycle for Creative Design Services
</h4>
            <p></p>
        </div>
    )
}

export default SectionTitleWhyChooseus;